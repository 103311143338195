import React from "react";
import { graphql } from "gatsby";

export default function BlogPost({ data }) {
    return <div dangerouslySetInnerHTML={{ __html: data.mjml.html }} />;
}

export const query = graphql`
    query($slug: String!) {
        mjml(fields: { slug: { eq: $slug } }) {
            html
        }
    }
`;
